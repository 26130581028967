import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Header from "/src/components/pages/products/Header";
import Benefits from "/src/components/pages/products/Benefits";

const QuestProducts = ({data}) => {

    const {quest} = data

    return (
        <Layout lang={quest.lang} seo={quest.SEO}>
            {quest.product.map((item, index) => {
                if (item.description2) {
                    return (
                        <>
                            <Header
                                title_white={item.title_white}
                                title_color={item.title_color}
                                subtitle={item.subtitle}
                                brand_color="one-identity-primary"
                                url={item.button}
                                url_download={item.button_download}
                                src={item.avatar.url ? item.avatar.url : ""}
                                description={item.description}
                                description2={item.description2}
                                isLongDesc={true}
                                invertLotti={index % 2 !== 0}
                                customPadding="noBottom"
                            />
                            <Benefits
                                noID={true}
                                big_title={item.benefits_title}
                                benefits={item.benefits_card}
                                button={item.benefits_button}
                                button_style="one-identity-primary"
                                customPadding="noTop"
                            />
                        </>
                    )
                } else {
                    return (
                        <>
                            <Header
                                title_white={item.title_white}
                                title_color={item.title_color}
                                brand_color="one-identity-primary"
                                subtitle={item.subtitle}
                                url={item.button}
                                url_download={item.button_download}
                                src={item.avatar.url ? item.avatar.url : ""}
                                description={item.description}
                                invertLotti={index % 2 !== 0}
                                customPadding="noBottom"
                            />
                            <Benefits
                                noID={true}
                                big_title={item.benefits_title}
                                benefits={item.benefits_card}
                                button={item.benefits_button}
                                button_style="one-identity-primary"
                                customPadding="noTop"
                            />
                        </>
                    )

                }
            })}
        </Layout>
    )
}

export const query = graphql`
   query GetSingleQuestProducts($locale: String) {
         quest: strapiQuestProducts(lang: { eq: $locale }) {
          lang
          product {
            title_white
            title_color
            subtitle
            button {
              link
              name
            }
            button_download {
              file {
                url
              }
              name
            }
            avatar {
              url
            }
            description
            description2
            benefits_title
            benefits_card {
              id
              title
            }
            benefits_button {
              id
              link
              name
            }
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default QuestProducts